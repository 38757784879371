import React, { useState } from "react";
import "semantic-ui-css/semantic.min.css";

const SwitchButton = ({ current, handleAction, ID, Permissions }) => {
  const [checked, setChecked] = useState(current);

  const handleChange = () => {
    setChecked(!checked);
    handleAction(ID, Permissions);
  };

  return (
    <div className="ui toggle checkbox">
      <input
        type="checkbox"
        name="toggle"
        checked={checked}
        onChange={handleChange}
      />
      <label>{checked ? "Enabled" : "Disabled"}</label>
    </div>
  );
};

export default SwitchButton;
