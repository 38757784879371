import { BrowserRouter } from "react-router-dom";
import "./App.css";
import DashboardRoutes from "./Components/DashboardRoutes";
import { useLayoutEffect } from "react";

function App() {
  useLayoutEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keydown", // Use keydown instead of keypress
    ];

    const getCookieValue = (name) => {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + "=")) {
          return decodeURIComponent(cookie.substring(name.length + 1));
        }
      }
      return null;
    };

    const handleGetCookie = () => {
      const pathName = window.location.pathname;
      if (pathName !== "/") {
        const myCookieValue = getCookieValue("loginCookies");
        if (myCookieValue && myCookieValue !== null) {
          // console.log('Value of myCookie:', myCookieValue);
        } else {
          localStorage.clear();
          window.location.pathname = "/";
          // console.log('myCookie not found.');
        }
      }
    };

    const eventHandlers = [];
    for (const event of events) {
      const handler = () => {
        handleGetCookie();
      };
      eventHandlers.push(handler);
      window.addEventListener(event, handler);
    }

    return () => {
      for (const event of events) {
        const handler = eventHandlers.pop();
        window.removeEventListener(event, handler);
      }
    };
  }, []);

  return (
    <BrowserRouter>
      <DashboardRoutes />
    </BrowserRouter>
  );
}

export default App;
