import { CSmartTable } from "@coreui/react-pro";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  capitalizeWords,
  changeGMTFormate,
  StatusConversion,
} from "../../Components/allFunctions";
import toast from "react-hot-toast";
import SwitchButton from "../../Components/SwitchButton";
import FileUploadModal from "./UploadModal";

const CMS_ENDPOINT =
  process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;

const TrainerTable = (props) => {
  const lang = props.Language;
  const navigate = useNavigate();
  const EnterpriseID = props.enterpriseId;

  const [loading, setLoading] = useState(false);
  const [Trainers, setTrainers] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);

  const customItemsPerPageLabel =
    lang === "en" ? "Items per Page:" : "项目/页 : ";
  const customTableFilterLabel = lang === "en" ? "Filter" : "筛选";

  const columns = [
    {
      key: "id",
      label: "ID",
      filter: false,
      sorter: false,
    },
    {
      key: "ID",
      label: lang === "en" ? "TrainerID" : "运动鞋",
    },

    {
      key: "EnterpriseID",
      label: lang === "en" ? "EnterpriseID" : "企业ID",
    },
    {
      key: "Email",
      label: lang === "en" ? "Email" : "电子邮件",
    },
    {
      key: "RoleID",
      label: lang === "en" ? "RoleID" : "角色ID",
    },
    {
      key: "Status",
      label: lang === "en" ? "Status" : "地位",
    },
    {
      key: "CanCreateOrders",
      label: lang === "en" ? "Create Order" : "创建订单",
      _style: { width: "10%" },
      filter: false,
      sorter: false,
    },
    {
      key: "CanCreateTeam",
      label: lang === "en" ? "Create Team" : "创建团队  ",
      _style: { width: "10%" },
      filter: false,
      sorter: false,
    },
  ];

  const handleGetAllTrainersByEnterprise = async () => {
    try {
      setLoading(true);
      const result = await fetch(
        `${CMS_ENDPOINT}/trainer/all/${EnterpriseID}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      let data = await result.json();
      if (result.ok) {
        setTrainers(data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      toast.error(error.message);
      console.log("Fetch all Trainer by enterprise -", error);
    }
  };

  const handleTrainerCanCreateOrders = async (TrainerID, Permissions) => {
    if (Permissions.hasOwnProperty("CanCreateOrders")) {
      Permissions.CanCreateOrders = !Permissions.CanCreateOrders;
    }

    fetch(`${CMS_ENDPOINT}/trainer/${TrainerID}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Permissions: Permissions,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUpdateTrigger((prev) => !prev); // Toggle updateTrigger to rerun useEffect
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const handleTrainerCanCreateTeams = async (TrainerID, Permissions) => {
    if (Permissions.hasOwnProperty("CanCreateTeam")) {
      Permissions.CanCreateTeam = !Permissions.CanCreateTeam;
    }

    fetch(`${CMS_ENDPOINT}/trainer/${TrainerID}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Permissions: Permissions,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setUpdateTrigger((prev) => !prev); // Toggle updateTrigger to rerun useEffect
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  useEffect(() => {
    handleGetAllTrainersByEnterprise();
  }, [updateTrigger]);

  return (
    <div className="container-fluid">
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
        <h1 className="h3 mb-0 text-gray-800 HeadTitles">
          {lang === "en" ? "Trainers" : "运动鞋"}
        </h1>
      </div>

      <div className="row">
        <div className="col-xl-12 col-md-12 mb-12">
          <div className="card shadow mb-4 flaged">
            <div className="card-body orders-table">
              <div className="custom-table">
                <FileUploadModal
                  EnterpriseID={EnterpriseID}
                  setUpdateTrigger={setUpdateTrigger}
                />

                <CSmartTable
                  responsive="true"
                  items={Trainers}
                  columns={columns}
                  columnSorter
                  tableFilter
                  tableFilterPlaceholder={
                    lang === "en" ? "Search..." : "搜索..."
                  }
                  tableFilterLabel={customTableFilterLabel}
                  pagination
                  loading={loading}
                  itemsPerPage={20}
                  itemsPerPageSelect
                  itemsPerPageLabel={customItemsPerPageLabel}
                  tableProps={{
                    hover: true,
                    responsive: true,
                    striped: true,
                    className: "flagged-table",
                  }}
                  scopedColumns={{
                    id: (item, i) => {
                      return <td>{i + 1}.</td>;
                    },

                    CanCreateOrders: (item, i) => (
                      <td key={item.ID}>
                        <SwitchButton
                          current={item?.Permissions?.CanCreateOrders === true}
                          ID={item?.ID}
                          Permissions={item?.Permissions}
                          handleAction={handleTrainerCanCreateOrders}
                        />
                      </td>
                    ),
                    CanCreateTeam: (item, i) => (
                      <td key={item.ID}>
                        <SwitchButton
                          current={item?.Permissions?.CanCreateTeam === true}
                          ID={item?.ID}
                          Permissions={item?.Permissions}
                          handleAction={handleTrainerCanCreateTeams}
                        />
                      </td>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainerTable;
