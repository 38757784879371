import { useParams, Link } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalTitle,
  CModalBody,
  CModalFooter,
  CLoadingButton,
  CFormCheck,
  CForm,
  CFormTextarea,
  CBadge,
} from "@coreui/react-pro";
import toast, { Toaster } from "react-hot-toast";
import Form from "react-bootstrap/Form";
import { saveAs } from "file-saver";
import JSZip from "jszip";

export default function OrderSingle({
  Orderjson,
  itemVal,
  i,
  fulfilled,
  lang,
  OrderDBData,
}) {
  const [downloadFolders, setDownloadFolders] = useState([]);

  const scansObj = Orderjson[0]?.scans;
  const feetScans = scansObj["feetscans"]?.Scans;
  const insolescansScans = scansObj["insolescans"]?.Scans;
  const dbdata = Orderjson[0]?.dbOrderData;

  const dbObject = OrderDBData.reduce((acc, item) => {
    acc[item.ItemID] = item;
    return acc;
  }, {});

  const downloadFile = (filePath, fileName = "Example-PDF-file.jpeg") => {
    fetch(
      "https://images.unsplash.com/photo-1608231975456-2f2d9fb1b49b?crop=entropy&cs=tinysrgb&fit=crop&fm=jpg&h=800&ixid=MnwxfDB8MXxyYW5kb218MHx8fHx8fHx8MTcwNDI3OTIxNQ&ixlib=rb-4.0.3&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1900",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/image",
          // "Content-Type": "application/octet-stream",
        },
      }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;

        document.body.appendChild(link);

        link.click();

        link.parentNode.removeChild(link);
      });
  };

  const downloadImage = async (Path, ID) => {
    await fetch(`${CMS_ENDPOINT}/SignedURLs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ Path }),
    })
      .then((response) => response.json())
      .then((data) => {
        const signedURLs = data?.url;

        const link = document.createElement("a");
        link.download = ID;

        link.href = signedURLs;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        link.click();

        // downloadZipFromUrls(signedURLs, ID);
      })
      .catch((error) => {
        console.log("Error:" + error.message);
      });
  };

  const downloadZipFromUrls = async (url, ID) => {
    const localUrl = url;
    try {
      const apiUrl = `${CMS_ENDPOINT}/DownloadFile`;

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          url: localUrl,
        }),
      });

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      console.log("URL", url);

      link.setAttribute("download", `${ID}.ply`); // Specify the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const params = useParams();
  const CMS_ENDPOINT =
    process.env.REACT_APP_API_BASE_URL + process.env.REACT_APP_CMS_ROUTE;
  // console.log(localStorage)
  const user = localStorage.getItem("id");
  const [visible, setVisible] = useState(false);
  const [tracking_number, setTracking_Number] = useState("");
  const [tracking_url, setTracking_Url] = useState("");
  const [shipping_company_name, setShipping_Company_Name] = useState("");
  const [FulfiledLoader, setFulfiledLoader] = useState(false);
  const [OrderStatus, setOrderStatus] = useState("");

  const getValueByName = (name, data) => {
    const item = data.find((item) => item.name === name);
    if (item) {
      return item.value;
    }

    const sanitizedName = name.replace(/\s+/g, "");
    const sanitizedItem = data.find((item) => item.name === sanitizedName);
    // console.log(sanitizedItem.value)
    if (sanitizedItem) {
      return sanitizedItem.value;
    }

    const sItem = data.find((item) => item.name === "Fit");
    if (sItem) {
      return sItem.value;
    }

    return "-";
  };

  let getIndex =
    Orderjson[0].fulfillments &&
    Orderjson[0].fulfillments.findIndex((fulfillment) => {
      return (
        fulfillment.line_items.findIndex((item) => item.id === itemVal.id) !==
        -1
      );
    });

  const changeHandler = (e) => {
    const status = e.target.value;
    if (fulfilled == "Unfulfilled") {
      if (status === "fulfilled") {
        setVisible(true);
      } else
        fetch(`${CMS_ENDPOINT}/OrderStatus`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderId: params?.id,
            productId: itemVal?.product_id,
            orderStatus: status,
          }),
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.status) {
              toast.success(lang === "en" ? "Success" : "成功");
              console.log(response);
              setOrderStatus(status);
            } else {
              toast.error(response.message);
            }
          })
          .catch((err) => {
            toast.error(err.message);
          });
    } else {
      toast.error(lang === "en" ? "Order fulfilled!!!" : "订单已完成！！！");
    }
  };
  //  console.log(OrderStatus)
  const SaveFullfilled = async (e, line_items_id, quantity) => {
    if (
      tracking_number !== "" &&
      (tracking_url !== "") & (shipping_company_name !== "")
    ) {
      try {
        setFulfiledLoader(true);
        await fetch(`${CMS_ENDPOINT}/OrderFulfillment`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: params?.id,
            tracking_number: tracking_number,
            tracking_url: tracking_url,
            shipping_company_name: shipping_company_name,
            line_items_id: line_items_id,
            quantity: quantity,
          }),
        })
          .then((response) => response.json())
          .then(async (data) => {
            if (data.status) {
              await fetch(`${CMS_ENDPOINT}/OrderStatus`, {
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  orderId: params?.id,
                  productId: itemVal?.product_id,
                  orderStatus: "fulfilled",
                }),
              })
                .then((response) => response.json())
                .then((response) => {
                  if (response.status) {
                    const dddata = {
                      line_items: [{ id: line_items_id }],
                      tracking_number: tracking_number,
                      tracking_url: tracking_url,
                      tracking_company: `http://${shipping_company_name}`,
                    };
                    Orderjson[0].fulfillments.push(dddata);
                    setFulfiledLoader(false);
                    // setFulfilled(e);
                    setVisible(false);
                    setTracking_Number("");
                    setTracking_Url("");
                    setShipping_Company_Name("");
                    toast.success(lang === "en" ? "Success" : "成功");
                    setOrderStatus("fulfilled");
                  } else {
                    toast.error(response.message);
                  }
                })
                .catch((err) => {
                  toast.error(err.message);
                });
            } else {
              //   toast.error("Fulfilled order can not be UnFulfilled")
              setFulfiledLoader(false);
              console.log("Fulfilled order can not be UnFulfilled");
            }
          });
        // }
      } catch (error) {
        setFulfiledLoader(false);
        console.log(error.message);
      }
    } else {
      setFulfiledLoader(false);
      toast.error(lang === "en" ? "Please fill details" : "请填写详细信息");
    }
  };

  const getOrderStatus = () => {
    fetch(`${CMS_ENDPOINT}/OrderStatus/${params?.id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setOrderStatus(data.data[0]?.OrderStatus ?? "Unfulfilled");
      });
  };
  const getTypeOfFinish = (name, properties) => {
    try {
      let result = getValueByName(name, properties);

      switch (result) {
        case "Luxe":
          result = "Luxe (Versatility)";
          break;
        case "Plush":
          result = "Plush (Comfort)";
          break;
        case "Response":
          result = "Response (Performance)";
          break;

        default:
          break;
      }

      return result;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getOrderStatus();
  }, []);

  return (
    <>
      <div className="row" key={`orderdetails${i}`}>
        <div className="col-md-12">
          <div className="card shadow mb-4">
            <div className="card-body item-card">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between mb-3">
                    {/* <a
                      href="https://groovscans-shopify.s3.amazonaws.com/f94a0416-8a8d-4c6d-8a2c-91e9e42134d5/sock_liner/accepted/010324/mesh/170427541678689920.ply?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWQHLKZXPI4NQMOMF%2F20240103%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240103T120319Z&X-Amz-Expires=300&X-Amz-Signature=7483625ad811373c1258b86cb768f9e7ee4e1bd041b283c11fb6bde5fe9fc476&X-Amz-SignedHeaders=host"
                      download="InsoleScan"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Insole1
                    </a>
                    <a
                      href="https://groovscans-shopify.s3.amazonaws.com/f94a0416-8a8d-4c6d-8a2c-91e9e42134d5/sock_liner/accepted/010324/pc/170427541679273600.ply?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAWQHLKZXPI4NQMOMF%2F20240103%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240103T120322Z&X-Amz-Expires=300&X-Amz-Signature=34b0284c2513734f0399d6d7d25f90369b253fadddb7247973575e1ea9dfee53&X-Amz-SignedHeaders=host"
                      download="InsoleScan"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Insole2
                    </a> */}
                    <h5 className="order-details-title">
                      {lang === "en" ? "Item Details" : "项目明细"}
                    </h5>

                    <div className="d-flex justify-content-between ">
                      <label className="fulfiledSelectLabel">
                        {lang === "en" ? "Order Status" : "訂單狀態"}
                      </label>
                      <select
                        className="custom-select"
                        value={
                          OrderStatus
                          // getIndex != undefined && getIndex >= 0
                          //   ? "fulfilled"
                          // : ""
                        }
                        // onChange={() => setVisible(true)}
                        onChange={changeHandler}
                      >
                        <option value="" disabled>
                          {lang === "en" ? "Unfulfilled" : "未實現"}
                        </option>
                        <option
                          value="awaiting-review"
                          disabled={fulfilled == "Fulfilled"}
                        >
                          &nbsp;&nbsp;&nbsp;
                          {lang === "en" ? "Awaiting Review" : "等待审核"}
                        </option>
                        <option
                          value="reviewed"
                          disabled={fulfilled == "Fulfilled"}
                        >
                          &nbsp;&nbsp;&nbsp;
                          {lang === "en" ? "Reviewed" : "已审核"}
                        </option>
                        <option
                          value="manufactured"
                          disabled={fulfilled == "Fulfilled"}
                        >
                          &nbsp;&nbsp;&nbsp;
                          {lang === "en" ? "Manufactured" : "已生产"}
                        </option>
                        <option
                          value="quality-assured"
                          disabled={fulfilled == "Fulfilled"}
                        >
                          &nbsp;&nbsp;&nbsp;
                          {lang === "en" ? "Quality Assured" : "质量已把关"}
                        </option>
                        <option
                          value="packaging"
                          disabled={fulfilled == "Fulfilled"}
                        >
                          &nbsp;&nbsp;&nbsp;
                          {lang === "en" ? "Packaging" : "包装"}
                        </option>
                        <option
                          value="shipped"
                          disabled={fulfilled == "Fulfilled"}
                        >
                          &nbsp;&nbsp;&nbsp;
                          {lang === "en" ? "Shipped" : "已发货"}
                        </option>
                        <option value="fulfilled">
                          &nbsp;&nbsp;&nbsp;
                          {lang === "en" ? "Fulfilled" : "實現了"}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="row justify-content-between">
                    <div className="col-md-2 groov-item-image">
                      <img
                        src={itemVal.image}
                        alt="insole-full"
                        className="img-fluid"
                      />
                    </div>
                    <div className="col-md-4 ellipsis">
                      <h5 className="order-details-title">
                        {lang === "en" ? "Configuration" : "配置"}
                      </h5>
                      <table
                        className="table table-borderless"
                        key={`configuration${i}`}
                      >
                        <tbody>
                          <tr>
                            <td>{lang === "en" ? "Nickname" : "昵称"}</td>
                            <td>
                              {getValueByName("Nick Name", itemVal.properties)}
                            </td>
                          </tr>
                          <tr>
                            <td>{lang === "en" ? "Length" : "长度"}</td>
                            <td>{itemVal.name}</td>
                          </tr>

                          <tr>
                            <td>{lang === "en" ? "Quantity" : "数量"}</td>
                            <td>{itemVal.quantity}</td>
                          </tr>
                          <tr>
                            <td>{lang === "en" ? "Fit" : "适配"}</td>
                            <td>
                              {getValueByName(
                                "Fit To Shoe",
                                itemVal.properties
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{lang === "en" ? "Shoes" : "鞋"}</td>
                            <td>
                              {getValueByName("Shoe Type", itemVal.properties)}
                            </td>
                          </tr>
                          <tr>
                            <td>{lang === "en" ? "Pain Area" : "疼痛区域"}</td>
                            <td>
                              {getValueByName("Pain Area", itemVal.properties)}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {lang === "en"
                                ? "Activity Intensity"
                                : "活動強度"}
                            </td>
                            <td>
                              {getValueByName(
                                "Activity Intensity",
                                itemVal.properties
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{"Type Of Finish"}</td>
                            <td>
                              {getTypeOfFinish(
                                "Type Of Finish",
                                itemVal.properties
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{"Groov Shape"}</td>
                            <td>
                              {getValueByName(
                                "Groov Shape",
                                itemVal.properties
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="vertical-line"></div> */}
                    <div className="col-md-3">
                      <h5 className="order-details-title">
                        {lang === "en" ? "Personal Details" : "电话号码"}

                        <span className="badgeForPlayer">
                          {OrderDBData[i]?.RepeatedPlayer ? "Repeated " : "New"}
                        </span>
                      </h5>
                      <table
                        className="table table-borderless"
                        key={`personal${i}`}
                      >
                        <tbody>
                          <tr>
                            <td>{"Player Name"}</td>
                            <td>{dbObject?.[itemVal?.id]?.["PlayerName"]}</td>
                          </tr>
                          <tr>
                            <td>{"Team Name"}</td>
                            <td>{dbObject?.[itemVal?.id]?.["TeamName"]}</td>
                          </tr>
                          <tr>
                            <td>{lang === "en" ? "Height" : "身高"}</td>
                            <td>
                              {getValueByName("Height", itemVal.properties)}
                            </td>
                          </tr>
                          <tr>
                            <td>{lang === "en" ? "Weight" : "体重"}</td>
                            <td>
                              {getValueByName("Weight", itemVal.properties)} lbs
                            </td>
                          </tr>
                          <tr>
                            <td>{lang === "en" ? "Age" : "年龄"}</td>
                            <td>
                              {getValueByName("Age", itemVal.properties)} yrs
                            </td>
                          </tr>
                          <tr>
                            <td>{lang === "en" ? "Gender" : "性别"}</td>
                            {/* <td>{getValueByName("Sex", itemVal.properties)}</td> */}
                            <td>{dbObject?.[itemVal?.id]?.["Gender"]}</td>
                          </tr>
                          <tr>
                            <td>{lang === "en" ? "Shoe Size" : "鞋码"}</td>
                            <td>
                              {getValueByName("Shoe Size", itemVal.properties)}
                            </td>
                          </tr>
                          <tr>
                            <td>{"Groov Shape"}</td>
                            <td>
                              {getValueByName(
                                "Groov Shape",
                                itemVal.properties
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{"Non Standard Width"}</td>
                            <td>
                              {getValueByName(
                                "Non Standard Width",
                                itemVal.properties
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>{"Aditional Details"}</td>
                            <td>
                              {getValueByName(
                                "Aditional Details",
                                itemVal.properties
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-3">
                      <h5 className="order-details-title ">
                        {lang === "en" ? "Scans" : "扫描"}
                      </h5>
                      <div className="row justify-content-center">
                        {getValueByName("Fit To Shoe", itemVal.properties) ==
                        "Shoe-Specific" ? (
                          <div className="col-md-12">
                            <h5 className="mb-2 order-scan-head d-flex">
                              {lang === "en" ? "Shoe Scan" : "鞋扫描"}
                            </h5>
                            {insolescansScans.length && (
                              <ul className="scans-list">
                                {insolescansScans &&
                                  insolescansScans.map((item, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        downloadImage(item.Path, item.ID)
                                      }
                                    >
                                      <i className="fas fa-download fa-sm"></i>
                                      <span>{item.Type}</span>
                                    </li>
                                  ))}
                              </ul>
                            )}
                          </div>
                        ) : (
                          ""
                        )}

                        {itemVal.insoleScans.length > 0 ||
                        itemVal.footScans.length > 0 ? (
                          <>
                            {itemVal.insoleScans.length > 0 && (
                              <div className="col-md-12">
                                <h5 className="mb-2 order-scan-head d-flex">
                                  {lang === "en" ? "Insole Scan" : "鞋扫描"}
                                </h5>
                                <ul className="scans-list">
                                  {itemVal.insoleScans.map((item, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        downloadImage(item.Path, item.ID)
                                      }
                                    >
                                      <i className="fas fa-download fa-sm"></i>
                                      <span>{`${item.ScanAngle} (${item.Type})`}</span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}

                            {itemVal?.footScans?.length > 0 && (
                              <div className="col-md-12">
                                <h5 className="mb-2 order-scan-head d-flex">
                                  {lang === "en" ? "Foot Scan" : "足部掃描"}
                                </h5>

                                <ul className="scans-list">
                                  {itemVal.footScans.map((item, index) => (
                                    <li
                                      key={index}
                                      onClick={() =>
                                        downloadImage(item.Path, item.ID)
                                      }
                                    >
                                      <i className="fas fa-download fa-sm"></i>
                                      <span>{`${item.ScanAngle} (${item.Type})`}</span>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                          </>
                        ) : (
                          <div className="col-md-12">
                            <p>No Scans Found</p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {Orderjson[0].fulfillments.length &&
                    getIndex != -1 &&
                    Orderjson[0].fulfillments[getIndex].tracking_number ? (
                      <div className="col-md-12" key={`trackinginfo${i}`}>
                        <h5 className="order-details-title">
                          {lang === "en" ? "Tracking Info" : "跟踪信息"}
                        </h5>
                        <div>
                          <div>
                            <h6>
                              {lang === "en" ? "Tracking Number" : "快递单号"}:{" "}
                              {
                                Orderjson[0].fulfillments[getIndex]
                                  .tracking_number
                              }
                            </h6>
                            <h6>
                              {lang === "en"
                                ? "Tracking Company:"
                                : "快递公司 :"}{" "}
                              {
                                Orderjson[0].fulfillments[getIndex]
                                  .tracking_company
                              }
                            </h6>
                            <h6>
                              {lang === "en" ? "Tracking URL:" : "跟踪网址 :"}{" "}
                              <Link
                                to={
                                  Orderjson[0].fulfillments[getIndex]
                                    .tracking_url
                                }
                              >
                                {
                                  Orderjson[0].fulfillments[getIndex]
                                    .tracking_url
                                }
                              </Link>
                            </h6>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Vendor create and update model */}
      <CModal
        alignment="center"
        visible={visible}
        // onClose={()=>}
        onClosePrevented={() => true}
      >
        <CModalHeader>
          <CModalTitle>
            {lang === "en" ? "Tracking Info" : "跟踪信息"}
          </CModalTitle>
        </CModalHeader>
        <CModalBody>
          <Form>
            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>
                {lang === "en" ? "Tracking Number" : "快递单号"}
              </Form.Label>
              <Form.Control
                type="text"
                placeholder={
                  lang === "en"
                    ? "Enter Your Tracking Number"
                    : "输入您的追踪号码"
                }
                value={tracking_number}
                onChange={(e) => setTracking_Number(e.target.value)}
              />
            </Form.Group>

            <Form.Group controlId="formGridEmail">
              <Form.Label>
                {lang === "en" ? "Tracking URL:" : "跟踪网址 :"}{" "}
              </Form.Label>
              <Form.Control
                type="email"
                placeholder={
                  lang === "en" ? "Enter Your Tracking URL" : "输入您的跟踪网址"
                }
                value={tracking_url}
                onChange={(e) => setTracking_Url(e.target.value)}
              />
            </Form.Group>
            <br />

            <Form.Group controlId="formGridEmail">
              <Form.Label>Shipping Company Name </Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Your Shipping Company Name"
                value={shipping_company_name}
                onChange={(e) => setShipping_Company_Name(e.target.value)}
              />
            </Form.Group>
          </Form>
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            close
          </CButton>
          {FulfiledLoader ? (
            <CLoadingButton color="info">Submit</CLoadingButton>
          ) : (
            <CButton
              color="primary"
              onClick={(e) =>
                SaveFullfilled(e.target.value, itemVal.id, itemVal.quantity)
              }
            >
              Submit
            </CButton>
          )}
        </CModalFooter>
      </CModal>
    </>
  );
}
