import React, { useState, useCallback } from "react";
import {
  CButton,
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from "@coreui/react-pro";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { CircleLoader } from "react-spinners";
import axios from "axios";
const API_ENDPOINT = process.env.REACT_APP_API_BASE_DEV_URL + "/api";

const FileUploadModal = (props) => {
  const { EnterpriseID, setUpdateTrigger } = props;
  const [uploadModal, setUploadModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [uploadedEntries, setUploadedEntries] = useState([]);

  const handleTrainerUpload = async () => {
    try {
      const formData = new FormData();
      formData.append("EnterpriseID", EnterpriseID);
      // formData.append("UserID", UserID);
      formData.append("file", files[0]);

      const response = await axios.post(
        `${API_ENDPOINT}/trainer/bulk-upload`,
        formData
      );
      if (response.statusText === "OK") {
        setUploadedEntries(response?.data?.trainers);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setUploadModal(false);
      setFiles([]);
      setUploadedEntries([]);
      toast.error(error.response.data.message);
    }
  };
  const handleConfirmUpload = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${API_ENDPOINT}/trainer/bulk-upload/confirm`,
        { trainers: uploadedEntries }
      );
      if (response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => {
          setLoading(false);
          setUploadModal(false);
          setUpdateTrigger(true);
        }, 1000);
        setFiles([]);

        setUploadedEntries([]);
      }
    } catch (error) {
      console.log(error);
      setUploadModal(false);
      setFiles([]);
      setLoading(false);
      setUploadedEntries([]);
      toast.error("Failed to add trainers");
    }
  };

  const toggleModal = (value) => {
    setUploadModal(value);
    setTimeout(() => {
      if (!value) {
        setFiles([]);
        setLoading(false);
        setUploadedEntries([]);
      }
    }, 1000);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setFiles(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <>
      <CButton
        onClick={() => toggleModal(true)}
        variant=""
        className="bulkUploadBtn"
      >
        Bulk Upload
      </CButton>
      <CModal
        visible={uploadModal}
        alignment="center"
        onClose={() => toggleModal(false)}
      >
        <CModalHeader>Upload Files </CModalHeader>
        <CModalBody>
          {isLoading ? (
            <div className="p-4">
              <CircleLoader className="LoaderSingleOrder" color="#1ca1ee" />
            </div>
          ) : (
            <>
              {uploadedEntries.length > 0 ? (
                <div>
                  {uploadedEntries.map((item, index) => (
                    <div key={index}>
                      <span>{index + 1}. </span>
                      <span>{item.Email}</span>
                      <span>
                        {item.alreadyExist ? " (Already Exist)" : " "}
                      </span>
                    </div>
                  ))}
                </div>
              ) : (
                <>
                  <div
                    {...getRootProps({ className: "dropzone" })}
                    style={dropzoneStyle}
                  >
                    <input {...getInputProps()} />
                    {files[0] ? (
                      <div className="">
                        File Name :
                        <span key={files[0]?.path}>{files[0]?.path}</span>
                      </div>
                    ) : (
                      <>
                        <p>
                          Drag 'n' drop your file here, or click to select file
                        </p>
                        <p>
                          Your file must have these columns Email ,
                          CanCreateTeam , CanCreateOrders.
                        </p>
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </CModalBody>
        <CModalFooter>
          <CButton color="secondary" onClick={() => toggleModal(false)}>
            Cancel
          </CButton>
          <CButton
            color="primary"
            disabled={
              uploadedEntries.length &&
              uploadedEntries.every((item) => item.alreadyExist)
            }
            onClick={() =>
              !uploadedEntries.length
                ? handleTrainerUpload()
                : handleConfirmUpload()
            }
          >
            {uploadedEntries.length > 0 ? "Confirm Upload" : "Upload"}
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

const dropzoneStyle = {
  border: "2px dashed #007bff",
  padding: "80px",
  textAlign: "center",
  cursor: "pointer",
};

export default FileUploadModal;
